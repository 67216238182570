<template>
  <div
    class="background-image position-relative d-flex align-items-start flex-column"
  >
    <div class="mb-auto page-content p-3">
      <!--  Page Header  -->
      <p class="mb-2 text-center">
        {{ currentQuestionIndex }} / {{ questions.length }}
      </p>

      <!--  Progress bar  -->
      <div
        class="progress rounded-0"
        style="height: 0.6rem; background-color: #ebebeb;"
      >
        <div
          class="progress-bar"
          role="progressbar"
          :style="
            `width: ${(100 * currentQuestionIndex) / questions.length -
              100 / questions.length}%`
          "
          :aria-valuenow="currentQuestionIndex"
          aria-valuemin="0"
          :aria-valuemax="questions.length - 1"
        />
      </div>

      <!--  Question component  -->
      <Question :question="question" />
    </div>

    <!--  Page Footer  -->
    <div class="page-footer p-3">
      <div class="row">
        <!--  Prev button  -->
        <div class="col-6 pe-2">
          <button
            class="btn rounded-0 w-100"
            style="background-color: #ebebeb;"
            type="button"
            @click="goToThePreviousQuestion"
          >
            {{ $t("previous") }}
          </button>
        </div>

        <!--  Next button  -->
        <div class="col-6 ps-2">
          <button
            class="btn btn-primary rounded-0 w-100"
            type="button"
            :disabled="!wasQuestionAnswered"
            @click="goToTheNextQuestion"
          >
            {{ $t("next") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Question from '@/components/QuestionBody'
export default {
  components: { Question },
  computed: {
    currentQuestionIndex () {
      return (this.$store.getters['questions/getCurrentQuestionIndex'](this.$route.params.questionGuid) + 1)
    },
    questions () {
      return this.$store.getters['questions/getQuestions']
    },
    question () {
      return this.$store.getters['questions/getQuestionWithId'](
        this.$route.params.questionGuid
      )
    },
    isTheFirstQuestion () {
      return this.$store.getters['questions/isTheFirstQuestion'](
        this.$route.params.questionGuid
      )
    },
    isTheLastQuestion () {
      return this.$store.getters['questions/isTheLastQuestion'](
        this.$route.params.questionGuid
      )
    },
    wasQuestionAnswered () {
      return this.$store.getters['questions/wasQuestionAnswered'](
        this.$route.params.questionGuid
      )
    }
  },
  beforeMount () {
    if (this.questions.length === 0) {
      this.$store.dispatch('questions/getQuestions', this.$route.params.configurationGuid)
    }
  },
  methods: {
    goToThePreviousQuestion () {
      if (this.isTheFirstQuestion || this.getPreviousQuestionId() === undefined) {
        this.$router.replace(`/configuration/${this.$route.params.configurationGuid}/thanks`)
      } else {
        this.emitter.emit('goToNewQuestion', true)
        this.$router.replace(`/configuration/${this.$route.params.configurationGuid}/questions/${this.getPreviousQuestionId()}`)
      }
    },
    goToTheNextQuestion () {
      if (this.isTheLastQuestion) {
        this.$router.push(`/configuration/${this.$route.params.configurationGuid}/survey_finished`)
        this.$store.dispatch('questions/sendQuestions', this.$route.params.configurationGuid)
      } else {
        this.emitter.emit('goToNewQuestion', true)
        this.$router.push(`/configuration/${this.$route.params.configurationGuid}/questions/${this.getNextQuestionId()}`)
      }
    },
    getPreviousQuestionId () {
      return this.$store.getters['questions/getPreviousQuestionId'](
        this.$route.params.questionGuid
      )
    },
    getNextQuestionId () {
      return this.$store.getters['questions/getNextQuestionId'](
        this.$route.params.questionGuid
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.background-image {
  height: 100%;

  > div {
    z-index: 2;
    width: 100vw;
  }

  .page-header {
    img {
      max-width: 8rem;
    }
  }

  @media (min-width: 800px) {
    .page-content,
    .page-footer {
      padding: 1rem 10rem !important;
    }
  }
  @media (min-width: 1280px) {
    .page-content,
    .page-footer {
      padding: 1rem 20rem !important;
    }
  }
}
</style>

<template>
  <div class="my-5">
    <div class="form-check mb-3" style="background-color: #ebebeb;" v-for="choice in question.surveyQuestionChoices"
         :key="choice.choiceGuid" @click="setChoice(choice)">
      <div class="p-3 w-100 h-auto cursor-pointer">
        <input class="form-check-input me-2" :checked="choiceGuid === choice.choiceGuid" type="radio" :name="question.questionGuid" :id="choice.choiceGuid">
        <label class="form-check-label" :for="choice.choiceGuid">
          {{ choice.expr2 }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      default: () => {return {}}
    }
  },
  data () {
    return {
      choiceGuid: ''
    }
  },
  beforeUpdate () {
    this.setDataFromQuestion()
  },
  beforeMount () {
    this.emitter.on('goToNewQuestion', () => {
      this.choiceGuid = ''
    })
    this.setDataFromQuestion()
  },
  methods: {
    setDataFromQuestion () {
      if (this.question.choice) {
        this.choiceGuid = this.question.choice.choiceGuid
      }
    },
    setChoice (choice) {
      this.choiceGuid = choice.choiceGuid
      this.$store.commit('questions/addChoice', {
        questionGuid: this.question.questionGuid,
        choiceGuid: this.choiceGuid,
        choicePosition: choice.position,
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form-check {
  min-height: 0;
}
</style>

<template>
  <div class="my-5">
    <ul class="list-group">
      <li class="list-group-item border-0 p-0 mb-3" style="background-color: #ebebeb;" v-for="choice in question.surveyQuestionChoices" :key="choice.choiceGuid" @click="setChoice(choice)">
        <div class="p-3 cursor-pointer" >
          <input class="form-check-input me-1" type="checkbox"  :checked="choiceGuids.includes(choice.choiceGuid)" :aria-label="choice.expr2">
          {{ choice.expr2 }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['question'],
  data () {
    return {
      choiceGuids: [],
      choicesPositions: []
    }
  },
  beforeUpdate () {
    this.setDataFromQuestion()
  },
  beforeMount () {
    this.setDataFromQuestion()
  },
  methods: {
    setDataFromQuestion () {
      if (this.question.choice) {
        this.choiceGuids = this.question.choice.choicesGUIDs.split(',')
        this.choicesPositions = this.question.choice.choicesPositions.split(',')
      }
    },
    setChoice (choice) {
      if (choice) {
        if (!this.choiceGuids.includes(choice.choiceGuid)) {
          this.choiceGuids.push(choice.choiceGuid)
          this.choicesPositions.push(choice.position)
        } else {
          this.choiceGuids.splice(this.choiceGuids.indexOf(choice.choiceGuid), 1)
          this.choicesPositions.splice(this.choicesPositions.indexOf(choice.position), 1)
        }
      }
      this.$store.commit('questions/addChoice', {
        questionGuid: this.question.questionGuid,
        choicesGUIDs: this.choiceGuids.toString(),
        choicesPositions: this.choicesPositions.toString(),
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>

<template>
  <div class="my-5" v-if="question">
    <p class="fs-4 fw-bold">
      {{ question.title }}
    </p>
    <p class="fs-5">
      <question-radio-answers
        v-if="question.type === 'radiogroup'"
        :question="question"
      />
      <question-checkboxes-answers
        v-if="question.type === 'checkbox'"
        :question="question"
      />
      <question-comment-answer
        v-if="question.type === 'text' || question.type === 'comment'"
        :question="question"
      />
    </p>
  </div>
</template>

<script>
import QuestionRadioAnswers from './QuestionRadioAnswers'
import QuestionCheckboxesAnswers from './QuestionCheckboxesAnswers'
import QuestionCommentAnswer from './QuestionCommentAnswer'
export default {
  components: { QuestionCommentAnswer, QuestionCheckboxesAnswers, QuestionRadioAnswers },
  props: ['question']
}
</script>
